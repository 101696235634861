import $ from 'jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import {initIntlInput} from "./scripts/intl-tel-input-initializer";
import {initPreventMultipleFormSubmissions} from "./scripts/prevent-multiple-form-submissions";
import {
    addPhoneValidation,
    addPinInputValidation,
    addTermsValidation,
    tagPhoneValidation,
    tagPinValidation,
    tagTermsValidation,
    validateForm
} from "./scripts/validation/formValidation";

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function clearClientCertificateOnIE() {
    try {
        document.execCommand("ClearAuthenticationCache", "false");
    } catch (e) {
        // do nothing
    }
}

function clearClientCertificateOnFF() {
    try {
        if (window.crypto && typeof window.crypto.logout === "function") {
            window.crypto.logout();
        }
    } catch (e) {
        // do nothing
    }
}

function clearClientCertificateOnChromium(clearPath) {
    var request = new XMLHttpRequest();
    request.open('GET', clearPath);
    request.send();
}

function clearCertificateCredentials(clearPath) {
    clearClientCertificateOnIE();
    clearClientCertificateOnFF();
    clearClientCertificateOnChromium(clearPath);
}

function abortCniAuthOnTimeout(authTimeoutSeconds, authTimeoutErrorPath) {
    setTimeout(function () {
        window.location.href = authTimeoutErrorPath;
    }, authTimeoutSeconds);
}

function isVisible(element) {
    if (!element) {
        return false;
    }
    const style = window.getComputedStyle(element);

    return  style.display!=='none' && style.visibility!== 'hidden';
}

const preventMultipleFormSubmissionsHandler = initPreventMultipleFormSubmissions()

window.ready = ready
window.abortCniAuthOnTimeout = abortCniAuthOnTimeout;
window.clearCertificateCredentials = clearCertificateCredentials;
window.initIntlInput = initIntlInput

let phoneValidationFunction = undefined

$( document ).ready(function() {

    $('.authTypeChoice').on('change', function () {
        if ($(this).val() === 'TELEMOVEL') {
            $('#username,#phone').removeAttr('disabled')
            $('#email').attr('disabled', 'true')
            $('#email').val('')
            $('input[' + tagPhoneValidation + ']').each(function() {
                const input = $(this).get(0)
                const iti = initIntlInput(input.id)
                phoneValidationFunction = addPhoneValidation(input, iti)
            })

            $('.emailBlock').css("display", "none")
            $('.phoneBlock').css("display", "")
        } else if ($(this).val() === 'EMAIL') {
            $('.emailBlock').css("display", "")
            $('.phoneBlock').css("display", "none")

            phoneValidationFunction = undefined
            $('#username,#phone').attr('disabled', 'true')
            $('#username,#phone').val('')
            $('#email').removeAttr('disabled')
        }
    });

    $('form').on('submit', function(event) {
        if (validateForm(event.originalEvent, phoneValidationFunction)) {
            preventMultipleFormSubmissionsHandler(event.originalEvent)
        }else{
            event.preventDefault();
        }
    })

    const forms = document.getElementsByTagName("form")
    for (let form of forms) {
        console.debug(`adding form event listener to form ${form.id}`)

        let inputs = form.getElementsByTagName("input")

        for (let input of inputs) {
            if (!isVisible(input)) {
                continue;
            }

            if (input.hasAttribute(tagPinValidation)) {
                addPinInputValidation(input)
            }else if (input.hasAttribute(tagTermsValidation)) {
                addTermsValidation(input)
            }else if (input.hasAttribute(tagPhoneValidation)) {
                let iti = initIntlInput(input.id)
                phoneValidationFunction = addPhoneValidation(input, iti)
            }
        }

        const $phoneEl = $('#username,#phone')
        if($phoneEl && isVisible($phoneEl.get(0))) {
            $('#email').attr('disabled', 'true')
            $('.emailBlock').css("display", "none")
        }
    }

});