import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js'

function initIntlInput(inputId) {
    let phoneInputElement = document.getElementById(inputId);

    if (phoneInputElement === null) {
        return;
    }
    let phoneErrorClass = phoneInputElement.classList.contains('is-invalid') ? 'is-invalid' : '';

    let input = document.querySelector(`#${inputId}`);

    return intlTelInput(input, {
        initialCountry: 'cv',
        preferredCountries: ['cv', 'pt', 'us', 'gb'],
        customContainer: "w-100 " + phoneErrorClass,
        hiddenInput: inputId,
        nationalMode: true,
        // any initialisation options go here
    });
}

export {initIntlInput};

