function initPreventMultipleFormSubmissions(e) {

    let form_already_submitted = {};

    function handleFormSubmit(event) {

        /*
         * Prevents multiple submissions by disabling the button on the second time the form is submitted and prevents further submissions.
         *
         * The button is only disabled on the second attempt because if the button is disabled before the form is submitted the first time
         * then the its value ( if it has any ) won't be sent on the request.
         * This is required on the pages where spring-webflow is used since each button triggers a different action based on its value.
         */

        let form = event.target;

        if (!form.hasAttribute("data-prevent-multiple-subs")) {
            return;
        }

        if (form_already_submitted[form.id] === true) {
            event.preventDefault();

            let buttons = form.getElementsByTagName("button")
            let len = buttons.length
            for (let i = 0; i < len; i++) {
                buttons[i].disabled = true
            }

            return;
        }

        event.submitter.innerText = "A Processar...";
        form_already_submitted[form.id] = true;
    }


    return handleFormSubmit

}

export {initPreventMultipleFormSubmissions}
